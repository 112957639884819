<template>
  <v-menu
    ref="timepicker"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="time"
        :label="label ? label : $t(initLabel)"
        :rules="rules ? rules : [v => !!v || $t('app.required')]"
        :disabled="isDisabled"
        :solo="solo"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      format="24hr"
      :no-title="notitle"
      :min="min"
      :max="max"
      scrollable
      @click:minute="$refs.timepicker.save(time)"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "Timepicker",
  props: {
    label: String,
    min: String,
    max: String,
    rules: [Boolean, Array],
    isDisabled: Boolean,
    solo: Boolean,
    outlined: Boolean,
    dense: Boolean,
    hideDetails: Boolean,
    initValue: String,
    setValue: String,
    notitle: Boolean,
    removeValue: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    time(newVal) {
      this.$emit("value", newVal);
    },
    isDisabled(value) {
      if (this.removeValue && value) {
        this.time = "";
      }
    },
    setValue(value) {
      if (value) this.time = value;
    },
    initValue(value) {
      this.time = value;
    }
  },
  mounted() {
    if (this.initValue) this.time = this.initValue;
  },
  data() {
    return {
      initLabel: "components.choose_time",
      time: this.initValue || "",
      menu: false
    };
  }
};
</script>
